import React from 'react';
import { Link } from 'gatsby';

import Image from '@components/image/image';
import Layout from '@components/layout';
import SEO from '@components/seo';
import ExpertApproval from '@components/expert-approval';
import PartnersSlider from '@components/partners-slider/partners-slider';
import ReviewNew from '@components/review-new/review-new';
import JoinTheCommunity from '@components/join-the-community/join-the-community';

import styles from './social-network-free-page.module.scss';

interface IRangeTheShopItem {
  filename_disk: string;
  range_title: string;
  path: string;
}

interface IProps {
  text: any;
  noindex?: boolean;
}

const SocialNetworkFreePage = ({ text, noindex = true }: IProps) => {
  const renderPrice = () => {
    if (text.hero_focus_price) return <span className={styles.price}>{text.hero_focus_price}</span>;
    return '';
  };
  return (
    <Layout>
      <SEO title={text.page_title} description={text.page_description} noindex={noindex} />
      <section className={styles.heroSection}>
        <div className={styles.heroImage}>
          <Image hideOnMobile filename={text.hero_background_image.filename_disk} />
          <Image hideBiggerThenMobile filename={text.hero_mobile_background_image.filename_disk} maxWidth={1500} />
        </div>
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            <div className={styles.heroColContent}>
              <h1 className={styles.heroTitle}>{text.hero_title}</h1>
              <p className={styles.subHeader}>{text.hero_text}</p>
              <p className={styles.heroFocus}>
                <span className={styles.border}>
                  {text.hero_focus} {text.hero_focus_price ? '|' : ''} {renderPrice()}
                </span>
              </p>
              <div data-testid="lp-get-started-button">
                <Link to="/consultation" className={styles.heroButton}>
                  {text.hero_button_label}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.whiteSection}>
        <h2>{text.how_it_work_header}</h2>
        <hr />
        <div className={styles.howItWorkItems}>
          {text?.how_it_works_items_list.map((item: any, index: number) => (
            <div key={index} className={styles.item}>
              <p className={styles.indexNumber}>{index + 1}</p>
              <h3 className={styles.title}>{item.title}</h3>
              <p className={styles.description}>{item.description}</p>
            </div>
          ))}
        </div>
      </section>
      <section className={styles.orangeSection}>
        <div className={styles.containerFlex}>
          <div className={`${styles.side} ${styles.textSide}`}>
            <h2>{text.personalized_header}</h2>
            <hr />
            {text.personalized_list.map((item: any) => (
              <p key={item.text}>{item.text}</p>
            ))}
            <div data-testid="lp-get-started-button">
              <Link
                data-testid="lp-get-started-button"
                to="/consultation"
                className={styles.personalizedButton}
              >
                {text.hero_button_label}
              </Link>
            </div>
          </div>
          <div className={styles.side}>
            <div className={styles.image}>
              <Image width={800} filename={text.personalized_image.filename_disk} />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.whiteSection}>
        <div className={styles.container}>
          <h2>{text.without_bs_header}</h2>
          <hr />
          <p>{text.without_bs_text}</p>
          <div className={styles.bsItemsWrap}>
            {text.without_bs_items.map((item: any) => (
              <div key={item.text} className={styles.bsItems}>
                <img src={`/images/${item.filename_disk}`} alt="" />
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={styles.orangeSection}>
        <div className={styles.container}>
          <h2>{text.range_header}</h2>
          <hr />
          <div className={styles.imageItems}>
            {text.shop_the_range_items.map((item: IRangeTheShopItem, index: number) => (
              <Link to={item.path} className={styles.imageItemLink}>
                <div key={index} className={styles.imageItem}>
                  <Image width={800} filename={item.filename_disk} alt={item.filename_disk} />
                  <h5>{item.range_title}</h5>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
      <ExpertApproval
        title="Expert approved"
        description="We combine the latest in nutritional science alongside your actual dietary and lifestyle data to create the perfect combination of nutrients for your body."
        experts={text.experts}
      />
      <PartnersSlider />
      <ReviewNew />
      <JoinTheCommunity />
    </Layout>
  );
};

export default SocialNetworkFreePage;

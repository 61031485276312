import { graphql, useStaticQuery } from 'gatsby';

export const useReviewText = () => {
  const { reviews } = useStaticQuery(graphql`
    query NewReviews {
      reviews: directusFacebookLanding {
        review_title
        reviews_items {
          review
          rating
        }
      }
    }
  `);
  return reviews;
};

import React from 'react';
// components
import Image from '@components/image/image';
// styles
import styles from './expert-approval.module.scss';

interface IProps {
  title: string;
  description: string;
  experts: any;
  backgroundColor?: string;
}

const ExpertApproval: React.FC<IProps> = ({ title, description, experts, backgroundColor }) => {
  return (
    <section className={backgroundColor ? styles[`section${backgroundColor}`] : styles.section}>
      <div className={styles.container}>
        <h2>{title}</h2>
        <hr />
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        <ul className={styles.items}>
          {experts.map((expert: any) => (
            <li key={expert.name} className={styles.item}>
              <div className={styles.image}>
                <Image filename={expert.filename_disk || expert.image.filename_disk} width={150} alt={expert.name} />
              </div>
              <div className={styles.details}>
                <p className={styles.name}>
                  <strong>{expert.name}</strong>
                </p>
                <p>
                  <strong>{expert.role}</strong>
                </p>
                <p className={styles.qualification}>{expert.qualification}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ExpertApproval;

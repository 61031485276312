import React from 'react';

import { useReviewText } from '@hooks/directusHooks/useReviewText';

import styles from './review-new.module.scss';
import { ReviewNewUI } from 'vitl-component-library';

const ReviewNew = () => {
  const review = useReviewText();

  return <ReviewNewUI title={review.review_title} items={review.reviews_items} className={styles.reviews} />;
};

export default ReviewNew;
